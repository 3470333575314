import roles from '../roles'
function parseJwt (token) {
  if(!token){
    return false
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  return user?.refresh_token
}

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  return user?.token
}

const updateLocalTokens = (token, refreshToken) => {
  const user = JSON.parse(localStorage.getItem("user"))
  user.token = token
  user.refresh_token = refreshToken
  localStorage.setItem("user", JSON.stringify(user))
}

const getUser = () => {
  return JSON.parse(localStorage.getItem("user"))
}

const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user))
}

const removeUser = () => {
  localStorage.removeItem("user")
}

const getRole = () => {
  const jwtPayload = parseJwt(getLocalAccessToken())
  return jwtPayload.user_role ?? roles.demo
}

const getTitle = () => {
  const jwtPayload = parseJwt(getLocalAccessToken())
  return jwtPayload.user_title ?? "N/A"
}

const isExpired = token => {
  const jwtPayload = parseJwt(token)
  const now = Date.now()/1000
  return ((jwtPayload.exp ?? 0) - now) <= 30
}

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalTokens,
  getUser,
  setUser,
  removeUser,
  getRole,
  getTitle,
  isExpired
}

export default TokenService