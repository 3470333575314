import { useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import AuthService from '../../services/auth.service'

export default function SignOut(props) {

    useEffect(() => {
        AuthService.logout()
        props.setUserStatus(false, 0)
    }, [props]);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}