const roles = {
    "customsAuthority": 1,
    "carrier": 2,
    "govAgent": 3,
    "developer": 4,
    "demo": 5,
    "inspektorFts": 6,
    "driver": 7
}

export default roles

export function roleName(role) {
    switch (role) {
        case roles.carrier: return 'Перевозчик'
        case roles.customsAuthority: return 'Сотрудник таможни'
        case roles.demo: return 'Демо гость'
        case roles.developer: return 'Разработчик'
        case roles.govAgent: return 'Гос служащий'
        case roles.inspektorFts: return 'Инспектор'
        case roles.driver: return 'Водитель'
        default: return 'Неопределенная роль'
    }
}