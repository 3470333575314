import { lazy, Suspense, useState, useEffect, useRef } from 'react'
import './App.css'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import 'react-notifications/lib/notifications.css'
import { NotificationContainer } from "react-notifications"
import SignOut from './views/pages/SignOut'
import LoaderIndicator from './components/UI/LoaderIndicator'
import TokenService from './services/token.service'

// Containers
const DefaultLayout = lazy(() => import('./layouts/DefaultLayout/DefaultLayout'))

const ShippingMapOnly = lazy(() => import('./modules/shipping/MapOnly'))

// Pages
const SignIn = lazy(() => import('./views/pages/SignIn'))

const NewVersion = lazy(() => import('./components/NewVersion'))

const initRole = TokenService.getRole()


export default function App() {

  const [isLoginIn, setIsLoginIn] = useState((TokenService.getLocalAccessToken()?.length && initRole) ? true : false)
  const [role, setRole] = useState(initRole)
  const [hasNewVersion, setHasNewVersion] = useState(false)

  const tidRef = useRef(null)

  const setUserStatus = (status, role) => {
    setIsLoginIn(status)
    setRole(role)
  }

  useEffect(() => {

    tidRef.current = setTimeout(checkVersion, 10000)

    async function checkVersion() {

      const response = await fetch("/version.json")

      if (response.ok) {
        const json = await response.json()

        if (process.env.REACT_APP_BUILD_NUMBER !== (json.buildVersion + "")) {
          setHasNewVersion(true)
        }
        
      }

      tidRef.current = setTimeout(checkVersion, 10000)
    }

  }, [])

  useEffect(() => () => {
    clearTimeout(tidRef.current)
  }, [])
   
  return (
    <>
      <HashRouter>
        <Suspense fallback={<LoaderIndicator />}>
          {hasNewVersion ? <NewVersion /> : null}
          <Routes>
            {!isLoginIn
              ?
              <>
                <Route exact path="/sign-in" element={<SignIn setUserStatus={setUserStatus} />} />
                <Route path="*" element={<Navigate to="/sign-in" replace />} />
              </>
              :
              <>
                <Route exact path="/sign-out" element={<SignOut setUserStatus={setUserStatus} />} />
                <Route exact path="/shipping/:id/map" name="Shipping map" element={<ShippingMapOnly />} />
                <Route path="*" name="Home" element={<DefaultLayout role={role} />} />
              </>
            }
          </Routes>
        </Suspense>
      </HashRouter>

      <NotificationContainer />
    </>
  )

}